import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-oxygen-list',
  templateUrl: './oxygen-list.component.html',
  styleUrls: ['./oxygen-list.component.css']
})
export class OxygenListComponent implements OnInit {

  oxygen: StockChart;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title) {
    this.meta.updateTag({ name: 'title', content: 'Artproject - oxygen list' });
    this.meta.updateTag({ name: 'description', content: 'Artproject - oxygen list' });
    this.meta.updateTag({ name: 'keywords', content: 'Artproject - oxygen list' });
    this.titleService.setTitle('Artproject');
  }

  ngOnInit() {
    this.listValueOxygen();

  }


  listValueOxygen() {
    this.http.get(`${environment.apiUrl}/oxygenList.php`)
      .subscribe((res: any) => {
        console.log('json oxygen', res);
        this.oxygen = new StockChart({
          rangeSelector: {
            selected: 1
          },
          title: {
            text: 'History Oxygen'
          },
          time: {
            useUTC: false,
            timezoneOffset: 3600,
            timezone: 'Europe/Rome'
          },
          series: [{
            tooltip: {
              valueDecimals: 2
            },
            name: 'perc. ',
            data: res

          }]
        } as any);
      });

  }

  // add point to chart serie
  refrash() {
    this.listValueOxygen()
  }


}
