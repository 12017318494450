import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
  title = 'Artproject';

  lastRecordDate = '';

  constructor(
    private http: HttpClient) {
  }

  ngOnInit() {
    this.lastRecordDateCall();
    interval(1000 * 60).subscribe(x => {
      this.lastRecordDateCall();
    });
  }

  lastRecordDateCall(){
    this.http.get(`${environment.apiUrl}/lastRecordRegistred.php`)
    .subscribe((res: any) => {
      console.log('lastDate', res);
      this.lastRecordDate = res;
      // point.update({series: [{data: res}]});
    });
  }
}
