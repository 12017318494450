import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { interval } from 'rxjs';

@Component({
  selector: 'app-humidity-instant',
  templateUrl: './humidity-instant.component.html',
  styleUrls: ['./humidity-instant.component.css']
})
export class HumidityInstantComponent implements OnInit {

  humidityInstant: Chart;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title) {
    this.meta.updateTag({ name: 'title', content: 'Wheatherman - humidity instant' });
    this.meta.updateTag({ name: 'description', content: 'Wheatherman - humidity instant' });
    this.meta.updateTag({ name: 'keywords', content: 'Wheatherman - humidity instant' });
    this.titleService.setTitle('Wheatherman');
  }

  ngOnInit() {
    this.lastValueHumidity();
    interval(1000 * environment.timeToRefresh).subscribe(x => {
      this.refrash();
    });
  }


  lastValueHumidity() {
    this.http.get(`${environment.apiUrl}/humidityInstant.php`)
      .subscribe((res: any) => {
        console.log('last humidity', res);
        this.humidityInstant = new Chart({
          chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false
          },
          pane: {
            startAngle: -150,
            endAngle: 150,
            background: [{
              backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, '#FFF'],
                  [1, '#333']
                ]
              },
              borderWidth: 0,
              outerRadius: '109%'
            }, {
              backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, '#333'],
                  [1, '#FFF']
                ]
              },
              borderWidth: 1,
              outerRadius: '107%'
            }, {
              // default background
            }, {
              backgroundColor: '#DDD',
              borderWidth: 0,
              outerRadius: '105%',
              innerRadius: '103%'
            }]
          },

          // the value axis
          yAxis: {
            min: 0,
            max: 100,

            minorTickInterval: 'auto',
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickPosition: 'inside',
            minorTickColor: '#666',

            tickPixelInterval: 30,
            tickWidth: 2,
            tickPosition: 'inside',
            tickLength: 10,
            tickColor: '#666',
            labels: {
              step: 2,
              rotation: 'auto'
            },
            title: {
              text: '%'
            },
            plotBands: [{
              from: 0,
              to: 20,
              color: '#DF5353' // red
            }, {
              from: 20,
              to: 40,
              color: '#DDDF0D' // yellow
            }, {
              from: 40,
              to: 70,
              color: '#55BF3B' // green
            }, {
              from: 70,
              to: 100,
              color: '#DF5353' // red
            }]
          },
          rangeSelector: {
            selected: 1
          },
          title: {
            text: 'Instant Humidity'
          },
          time: {
            useUTC: false,
            timezoneOffset: 3600,
            timezone: 'Europe/Rome'
          },
          series: [{
            name: 'Hum.',
            data: res,
            tooltip: {
              valueSuffix: ' %'
            }
          }]
        } as any);
      });

  }

  // add point to chart serie
  refrash() {
    // this.listValueTemperature()
    this.http.get(`${environment.apiUrl}/humidityInstant.php`)
      .subscribe((res: any) => {
        console.log('humidity update', res);
        var point = this.humidityInstant.removePoint(0);
        var point = this.humidityInstant.addPoint(res);
        // point.update({series: [{data: res}]});
      });
  }



}
