import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-temperature-list',
  templateUrl: './temperature-list.component.html',
  styleUrls: ['./temperature-list.component.css']
})
export class TemperatureListComponent implements OnInit {

  temperature: StockChart;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title) {
    this.meta.updateTag({ name: 'title', content: 'Wheatherman - temperature list' });
    this.meta.updateTag({ name: 'description', content: 'Wheatherman - temperature list' });
    this.meta.updateTag({ name: 'keywords', content: 'Wheatherman - temperature list' });
    this.titleService.setTitle('Wheatherman');
  }

  ngOnInit() {
    this.listValueTemperature();
  }


  listValueTemperature() {
    this.http.get(`${environment.apiUrl}/temperatureList.php`)
      .subscribe((res: any) => {
        console.log('json temperature', res);
        this.temperature = new StockChart({
          rangeSelector: {
            selected: 1
          },
          title: {
            text: 'History Temperature'
          },
          time: {
            useUTC: false,
            timezoneOffset: 3600,
            timezone: 'Europe/Rome'
          },
          series: [{
            tooltip: {
              valueDecimals: 2
            },
            name: 'temp. ',
            data: res

          }]
        } as any);
      });

  }

  // add point to chart serie
  refrash() {
    this.listValueTemperature()
  }

}
