import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-humidity-list',
  templateUrl: './humidity-list.component.html',
  styleUrls: ['./humidity-list.component.css']
})
export class HumidityListComponent implements OnInit {


  humidity: StockChart;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title) {
    this.meta.updateTag({ name: 'title', content: 'Wheatherman - humidity list' });
    this.meta.updateTag({ name: 'description', content: 'Wheatherman - humidity list' });
    this.meta.updateTag({ name: 'keywords', content: 'Wheatherman - humidity list' });
    this.titleService.setTitle('Wheatherman');
  }

  ngOnInit() {
    this.listValueHumidity();

  }


  listValueHumidity() {
    this.http.get(`${environment.apiUrl}/humidityList.php`)
      .subscribe((res: any) => {
        console.log('json humidity', res);
        this.humidity = new StockChart({
          rangeSelector: {
            selected: 1
          },
          title: {
            text: 'History Humidity'
          },
          time: {
            useUTC: false,
            timezoneOffset: 3600,
            timezone: 'Europe/Rome'
          },
          series: [{
            tooltip: {
              valueDecimals: 2
            },
            name: 'perc. ',
            data:  res

          }]
        } as any);
      });

  }

  // add point to chart serie
  refrash() {
    this.listValueHumidity()
  }

}
