import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StockChart, Chart } from 'angular-highcharts';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-output-graph',
  templateUrl: './output-graph.component.html',
  styleUrls: ['./output-graph.component.css']
})
export class OutputGraphComponent {
  stock: StockChart;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title) {
    this.meta.updateTag({ name: 'title', content: 'Wheatherman' });
    this.meta.updateTag({ name: 'description', content: 'Wheatherman' });
    this.meta.updateTag({ name: 'keywords', content: 'Wheatherman' });
    this.titleService.setTitle('Wheatherman');
  }

  ngOnInit() {
    this.listValueTemperature();
    
  }


  listValueTemperature() {
    this.http.get(`${environment.apiUrl}/api-test.php`)
      .subscribe((res: any) => {
        console.log('FILES', res);
        this.stock = new StockChart({
          rangeSelector: {
            selected: 1
          },
          title: {
            text: 'AAPL Stock Price'
          },
          series: [ {
            tooltip: {
              valueDecimals: 2
            },
            name: 'temp. ',
            data: res
            
          }]
        }as any);
      });
    
  }

  // add point to chart serie
  add() {
    this.stock.ref.series[0].addPoint([1565827860000, Math.floor(Math.random() * 100)]);
  }
}
