import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OutputGraphComponent } from './output-graph/output-graph.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { TemperatureListComponent } from './graph/temperature-list/temperature-list.component';
import { HumidityListComponent } from './graph/humidity-list/humidity-list.component';

import { TemperatureInstantComponent } from './graph/temperature-instant/temperature-instant.component';
import { HumidityInstantComponent } from './graph/humidity-instant/humidity-instant.component';

import { OxygenInstantComponent } from './graph/oxygen-instant/oxygen-instant.component';
import { OxygenListComponent } from './graph/oxygen-list/oxygen-list.component';
import { BatteryListComponent } from './graph/battery-list/battery-list.component';
import { BatteryInstantComponent } from './graph/battery-instant/battery-instant.component';

export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}

@NgModule({
  declarations: [
    AppComponent,
    OutputGraphComponent,
    TemperatureListComponent,
    HumidityListComponent,
    
    TemperatureInstantComponent,
    HumidityInstantComponent,
    
    OxygenInstantComponent,
    OxygenListComponent,
    BatteryListComponent,
    BatteryInstantComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ChartModule
  ],
  providers: [{ provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }],
  bootstrap: [AppComponent]
})
export class AppModule { }
